import { Injectable } from '@angular/core';
import { TranslocoService } from "@jsverse/transloco";
import { BehaviorSubject, Observable } from "rxjs";
import { LocalStorageService } from "./local-storage.service";

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private currentLangSubject: BehaviorSubject<string> = new BehaviorSubject<string>('en');

    constructor(private translocoService: TranslocoService, private localStorageService: LocalStorageService) {
        this.translocoService.langChanges$.subscribe(lang => {
            this.currentLangSubject.next(lang);
        });
        this.translocoService.setActiveLang(this.localStorageService.getSelectedLanguage() || 'en');
    }

    getCurrentLang(): string {
        return this.currentLangSubject.value;
    }

    getCurrentLangAsObservable(): Observable<string> {
        return this.currentLangSubject.asObservable();
    }

    setCurrentLang(lang: string): void {
        this.localStorageService.setSelectedLanguage(lang);
        this.translocoService.setActiveLang(lang);
    }
}
