import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'initials'
})
export class InitialsPipe implements PipeTransform {
    transform(fullName: string): string {
        if (!fullName) return '';
        const names = fullName.split(' ');
        const initials = names.map(name => name[ 0 ]).join('');
        return initials.toUpperCase();
    }
}
