<!-- Language switcher button that shows the current flag -->
<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Select Language" matTooltip="Change Language">
    <img [src]="currentFlag" alt="flag" width="32" height="32"> <!-- Precomputed currentFlag -->
</button>

<!-- Dropdown menu for selecting a language -->
<mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let lang of languages" (click)="switchLang(lang.code)" [disabled]="lang.code === (this.currentLang$ | async)">
        <div style="display: flex; flex-direction: row; align-items: center;">
            <img [src]="lang.flag" alt="{{ lang.label }}" width="32" height="32" style="margin-right: 8px;">
            <span class="btn-text">{{ lang.label }}</span> <!-- Wrapping text in a span for better control -->
        </div>
    </button>
</mat-menu>
