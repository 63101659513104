export interface UserProfileModel {
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    authenticated: boolean;
}

export const ANONYMOUS_USER_PROFILE_MODEL: UserProfileModel = {
    firstName: '',
    lastName: '',
    fullName: '',
    email: '',
    authenticated: false
}
