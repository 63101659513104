import { Injectable } from '@angular/core';
import { KeycloakService } from "keycloak-angular";
import { BehaviorSubject, Observable } from "rxjs";
import { ANONYMOUS_USER_PROFILE_MODEL, UserProfileModel } from "../models/user-profile.model";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationWrapperService {
    private readonly isLoggedInSubject: BehaviorSubject<boolean>;
    private readonly userProfileSubject: BehaviorSubject<UserProfileModel>;

    constructor(private readonly keycloakService: KeycloakService,
                private readonly router: Router) {
        this.isLoggedInSubject = new BehaviorSubject(false);
        this.userProfileSubject = new BehaviorSubject(ANONYMOUS_USER_PROFILE_MODEL);
        (async() => {
            await this.initialize();
        })();
    }

    private async initialize(): Promise<void> {
        const isLoggedIn = await this.keycloakService.isLoggedIn();
        this.isLoggedInSubject.next(isLoggedIn);

        if (isLoggedIn) {
            this.logTokenAndRoles();
            this.updateLoginStatus();
        } else {
            this.userProfileSubject.next(ANONYMOUS_USER_PROFILE_MODEL);
        }
    }

    public isLoggedIn(): Observable<boolean> {
        return this.isLoggedInSubject.asObservable();
    }

    public getUserProfile(): Observable<UserProfileModel> {
        return this.userProfileSubject.asObservable();
    }

    public async updateLoginStatus(): Promise<void> {
        this.isLoggedInSubject.next(this.keycloakService.isLoggedIn());
        if (this.keycloakService.isLoggedIn()) {
            const userProfile = await this.keycloakService.loadUserProfile();
            this.userProfileSubject.next({
                firstName: userProfile.firstName ?? '',
                lastName: userProfile.lastName ?? '',
                fullName: (userProfile.firstName ?? '') + ' ' + (userProfile.lastName ?? ''),
                email: (userProfile.email ?? ''),
                authenticated: true
            });
        } else {
            this.userProfileSubject.next(ANONYMOUS_USER_PROFILE_MODEL);
        }
    }

    public login(): void {
        this.keycloakService.login().then(() => {
            this.updateLoginStatus();
            this.logTokenAndRoles().catch(() => {
                console.error('unable to log token and roles');
            }); // Log token and roles after login
        }).catch(() => {
            this.updateLoginStatus();
        });
    }

    public logout(): void {
        this.router.navigate([ '/' ]).then(() => {
            this.keycloakService.logout().then(() => {
                this.updateLoginStatus();
            }).catch(() => {
                this.updateLoginStatus();
            });
        });
    }

    public isLoggedInSync(): boolean {
        return this.isLoggedInSubject.value;
    }

    public register(): void {
        this.keycloakService.register().then(() => {
            this.updateLoginStatus();
        }).catch(() => {
            this.updateLoginStatus();
        });
    }

    // Method to retrieve and log the token and roles
    private async logTokenAndRoles(): Promise<void> {
        try {
            const token = await this.keycloakService.getToken(); // Get token
            const roles = this.keycloakService.getUserRoles();   // Get roles

            console.log("User Token:", token);
            console.log("User Roles:", roles);
        } catch (error) {
            console.error("Failed to retrieve token or roles", error);
        }
    }
}
