<div class="access-denied-container">
    <mat-card class="access-denied-card">
        <mat-card-header>
            <mat-icon class="icon" color="warn">lock</mat-icon>
        </mat-card-header>
        <mat-card-content>
            <h2>Access Denied</h2>
            <p>You don't have permission to view this page.</p>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="primary" (click)="goHome()">Go to Home</button>
        </mat-card-actions>
    </mat-card>
</div>
