import { Component } from '@angular/core';
import { TranslocoService } from "@jsverse/transloco";
import { LanguageService } from "../../../services/language.service";
import { Observable } from "rxjs";

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrl: './language-switcher.component.scss'
})
export class LanguageSwitcherComponent {
    currentLang$: Observable<string>;
    currentFlag: string = 'en';

    languages = [
        { code: 'en', label: 'English', flag: 'assets/svg/flags/en.svg' },
        { code: 'de', label: 'Deutsch', flag: 'assets/svg/flags/de.svg' }
    ];

    constructor(private langService: LanguageService) {
        // Set the initial language and flag
        this.currentLang$ = this.langService.getCurrentLangAsObservable();
        this.updateCurrentFlag();
    }

    switchLang(lang: string) {
        this.langService.setCurrentLang(lang);
        this.updateCurrentFlag(); // Update the flag when the language changes
    }

    updateCurrentFlag() {
        // Update the current flag based on the selected language
        const currentLang = this.langService.getCurrentLang();
        const selectedLang = this.languages.find(lang => lang.code === currentLang);
        this.currentFlag = selectedLang ? selectedLang.flag : 'assets/svg/flags/en.svg';
    }
}
