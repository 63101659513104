import { Component } from '@angular/core';
import { Observable, of } from "rxjs";
import { AuthenticationWrapperService } from "../../../services/authentication-wrapper.service";
import { Router } from "@angular/router";
import { ANONYMOUS_USER_PROFILE_MODEL, UserProfileModel } from "../../../models/user-profile.model";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss'
})
export class HeaderComponent {
    isMenuOpen = false;
    isLoggedIn$: Observable<boolean> = of(false); // This is a placeholder. You should replace it with your actual authentication logic.
    userProfile$: Observable<UserProfileModel> = of(ANONYMOUS_USER_PROFILE_MODEL); // This is a placeholder. You should replace it with your actual authentication logic.

    constructor(private readonly authenticationWrapperService: AuthenticationWrapperService,
                private readonly router: Router) {
        this.isLoggedIn$ = this.authenticationWrapperService.isLoggedIn();
        this.userProfile$ = this.authenticationWrapperService.getUserProfile();
    }

    toggleMenu(): void {
        this.isMenuOpen = !this.isMenuOpen;
    }

    onLogin(): void {
        this.authenticationWrapperService.login();
    }

    onSignUp(): void {
        // Implement sign-up logic here
        this.authenticationWrapperService.register();
        console.log('Sign Up clicked');
    }

    onLogout(): void {
        // Implement logout logic here
        this.authenticationWrapperService.logout();
        console.log('Logout clicked');
    }

    onProfileClick(): void {
        this.router.navigate([ '/profile' ]);
    }
}
