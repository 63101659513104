<header class="header">
    <div class="header-content">
        <!-- Logo container on the left with brand name and slogan -->
        <div class="logo-container">
            <a routerLink="/" class="logo-container">
                <img src="../../../../assets/logo1.png" alt="logo" class="logo">
                <div class="brand-text">
                    <h1 class="brand-name" transloco="app.title">HiveTransfer</h1>
                    <p class="brand-slogan" transloco="app.slogan">No easier way to send files...</p>
                </div>
            </a>
        </div>

        <!-- Centered navigation menu -->
        <nav [ngClass]="{'nav-open': isMenuOpen}" class="nav-menu">
            <ul class="nav-list">
                <li class="nav-item">
                    <a href="#" transloco="header.menu.features">Features</a>
                    <ul class="dropdown">
                        <li><a href="#">Feature 1</a></li>
                        <li><a href="#">Feature 2</a></li>
                        <li><a href="#">Feature 3</a></li>
                    </ul>
                </li>
                <li class="nav-item">
                    <a href="#" transloco="header.menu.pricing">Pricing</a>
                </li>
                <li class="nav-item">
                    <a href="#" transloco="header.menu.resources">Resources</a>
                </li>
            </ul>
        </nav>

        <!-- Action buttons on the right -->
        <div class="action-buttons">
            <!-- Language switcher component -->
            <app-language-switcher></app-language-switcher>

            <!-- Login button -->
            <button mat-icon-button *ngIf="(this.isLoggedIn$ | async) === false" (click)="this.onLogin()" aria-label="Login" matTooltip="{{ ('header.button.login' | transloco) || 'Login' }}" matTooltipPosition="above">
                <mat-icon>login</mat-icon>
            </button>

            <!-- Sign Up button -->
            <button class="btn-register" mat-icon-button *ngIf="(this.isLoggedIn$ | async) === false" (click)="this.onSignUp()" aria-label="Sign Up" matTooltip="{{ ('header.button.signUp' | transloco) || 'Sign Up' }}" matTooltipPosition="above">
                <mat-icon>person_add</mat-icon>
            </button>

            <!-- Profile button -->
            <button class="btn-profile" mat-icon-button *ngIf="(this.isLoggedIn$ | async) === true" (click)="this.onProfileClick()" aria-label="Profile" matTooltip="{{ ('header.button.profile' | transloco) || 'Profile' }}" matTooltipPosition="above">
<!--                <mat-icon>person</mat-icon>-->
                <div class="avatar" *ngIf="userProfile$ | async as userProfile">
                    <span>{{ userProfile.fullName | initials }}</span>
                </div>
            </button>

            <!-- Logout button -->
            <button mat-icon-button *ngIf="(this.isLoggedIn$ | async) === true" (click)="this.onLogout()" aria-label="Logout" matTooltip="{{ ('header.button.logout' | transloco) || 'Logout' }}" matTooltipPosition="above">
                <mat-icon>logout</mat-icon>
            </button>

        </div>

        <!-- Burger menu for mobile -->
        <div class="burger-menu" (click)="toggleMenu()">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</header>
