<div class="asset-content-container">
    <h1>Current Background Image</h1>
    <p>
        You are currently viewing a randomly selected image from <a href="https://picsum.photos">Picsum Photos</a>.
    </p>
    <p>
        <strong>Image Information:</strong> This image is 1920x1080 pixels and is served dynamically from the Picsum Photos service.
    </p>
    <p>
        <a href="https://picsum.photos/1920/1080" target="_blank">Click here to view the original image</a>
    </p>
</div>
