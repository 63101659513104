import { Component } from '@angular/core';

@Component({
  selector: 'app-asset-content',
  templateUrl: './asset-content.component.html',
  styleUrl: './asset-content.component.scss'
})
export class AssetContentComponent {

}
