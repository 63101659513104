import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetContentComponent } from "./components/asset-content/asset-content.component";
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { MaterialModule } from "../material/material.module";
import { TranslocoModule } from "@jsverse/transloco";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { LanguageSwitcherComponent } from "./components/language-switcher/language-switcher.component";
import { InitialsPipe } from "./pipes/initials.pipe";
import { RouterModule } from "@angular/router";


@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        AssetContentComponent,
        AccessDeniedComponent,
        LanguageSwitcherComponent,
        InitialsPipe
    ],
    imports: [
        CommonModule,
        MaterialModule,
        TranslocoModule,
        RouterModule
    ],
    exports: [
        AssetContentComponent,
        HeaderComponent,
        FooterComponent,
        LanguageSwitcherComponent,
    ],
    providers: []
})
export class SharedModule {
    // empty.
}
