import { Injectable } from '@angular/core';
import { LocalStorageService as StorageService } from "angular-web-storage";
import { ShareNowReduxStoreState } from "../share-now/state/share-now.reducer";

export const ACCESS_TOKEN_KEY = 'access-token';
export const SELECTED_LANGUAGE_KEY = 'sel-lang';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    constructor(private storageService: StorageService) {
        // empty.
    }
    public removeAll(): void {
        this.storageService.clear();
    }

    public setSelectedLanguage(lang: string, expiry: number = 0): void {
        this.setItem(SELECTED_LANGUAGE_KEY, lang, expiry);
    }

    public getSelectedLanguage(): string {
        return this.getItem(SELECTED_LANGUAGE_KEY);
    }

    public removeSelectedLanguage(): void {
        this.removeItem(SELECTED_LANGUAGE_KEY);
    }

    public setPublicKey(exportedPublicKey: string, expiry: number = 0): void {
        this.setItem('publicKey', exportedPublicKey, expiry);
    }

    public getPublicKey(): string {
        return this.getItem('publicKey');
    }

    public removePublicKey(): void {
        this.removeItem('publicKey');
    }

    public setPrivateKey(privateKey: string, expiry: number = 0): void {
        this.setItem('privateKey', privateKey, expiry);
    }

    public getPrivateKey() {
        return this.getItem('privateKey');
    }

    public removePrivateKey(): void {
        this.removeItem('privateKey');
    }

    public getAccessToken(): string {
        return this.getItem(ACCESS_TOKEN_KEY);
    }

    public setAccessToken(authStorageKey: string, expiry: number = 0): void {
        this.setItem(ACCESS_TOKEN_KEY, authStorageKey, expiry);
    }

    public removeAccessToken(): void {
        this.removeItem(ACCESS_TOKEN_KEY);
    }

    public setUserId(id: number, exp: number = 0) {
        this.setItem('userId', id, exp);
    }

    public getUserId(): number {
        return this.getItem('userId');
    }

    public removeUserId() {
        this.removeItem('userId');
    }

    public setUploadsInProgress(uploadsInProgressAsJson: string) {
        if (uploadsInProgressAsJson) {
            this.setItem('uploadsInProgress', uploadsInProgressAsJson);
        }
    }

    public getUploadsInProgress(): string {
        return this.getItem('uploadsInProgress');
    }

    public setUploadsRequested(s: string) {
        this.setItem('uploadsRequested', s);
    }

    public getUploadsRequested(): string {
        return this.getItem('uploadsRequested');
    }

    public setUploadsFailed(s: string) {
        this.setItem('uploadsFailed', s);
    }

    public getUploadsFailed(): string {
        return this.getItem('uploadsFailed');
    }

    public setUploadsPaused(s: string) {
        this.setItem('uploadsPaused', s);
    }

    public getUploadsPaused(): string {
        return this.getItem('uploadsPaused');
    }

    public setTransfersVisible(value: boolean) {
        this.setItem('transfersVisible', value);
    }

    public isTransfersVisible(): boolean {
        return this.getItem('transfersVisible');
    }

    public setEncryptedMnemonics(encryptedMnemonics: string, expiry: number) {
        this.setItem('encryptedMnemonics', encryptedMnemonics, expiry);
    }

    public getEncryptedMnemonics(): string {
        return this.getItem('encryptedMnemonics');
    }

    public getShareNowState(): ShareNowReduxStoreState | undefined {
        let storedState: string = this.getItem('shareNowState');
        if (storedState) {
            return JSON.parse(storedState) as ShareNowReduxStoreState;
        }
        return undefined;
    }

    // private functions

    private setItem(key: string, value: any, expiry = 0): void {
        if (expiry != 0) {
            const now = new Date().getTime();
            const delta = (expiry - now) / 1000;

            this.storageService.set(key, value, delta, 's');
        } else {
            this.storageService.set(key, value);
        }
    }

    private getItem(key: string): any {
        return this.storageService.get(key);
    }

    private removeItem(key: string): void {
        if (key) {
            this.storageService.remove(key);
        }
    }
}
